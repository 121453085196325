// <Polyline :options="trackingPath()" />
export const polylineOptionsWith = ({ 
    pathPoints,
    isGeoDesic,
    color = '#005179', // Amtrak blue color
    opacity = 1.0,
    weight = 5,
    dashedLineEffect = false
}) => {
    // SVG symbol mimics 'dashed line' effect along polyline path
    const lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4
    }
    return {
        path: pathPoints,
        geodesic: isGeoDesic,
        strokeColor: color, 
        strokeOpacity: dashedLineEffect ? 0 : opacity,
        strokeWeight: weight,
        icons: dashedLineEffect ? [
            {
              icon: lineSymbol,
              offset: "0",
              repeat: "20px",
            }
          ] : undefined
      }
}

export const googleMapDarkThemeStyles = [ // Dark Theme from Google API docs: https://developers.google.com/maps/documentation/javascript/examples/style-array
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
    },
]

export const elevationInFeetForCoords = async ({ mapObject, coords, logger = null }) => {
    if (mapObject) {
        try {
            const elevService = new mapObject.api.ElevationService()
            const resp = await elevService.getElevationForLocations({
                locations: [coords]
            })
            const result = resp?.results?.[0]?.elevation // meters
            if (result) {
                return (result * 3.28084).toPrecision(5) // feet
            }
        } catch (error) {
            logger?.debug(`Error fetching elevation data for selected train: ${error}`)
        }
    }
    return 0
}