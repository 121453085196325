import Worker from 'worker-loader!../workers/geoPathWorker'
import * as Comlink from 'comlink'

const geoWorkerPoolSize = 8

const geoWorkerPool = Array.from(Array(geoWorkerPoolSize), () => {
    return Comlink.wrap(new Worker())
})

// From https://stackoverflow.com/questions/1527803/generating-random-whole-numbers-in-javascript-in-a-specific-range
/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const randomGeoWorker = () => {
    const workerIndex = getRandomInt(0, geoWorkerPoolSize - 1)
    return geoWorkerPool[workerIndex]
}

export const echo = async (testMsg) => {
    const worker = randomGeoWorker()
    return await worker.echo(testMsg)
}

export const pathFrom = async ({ origin, destination, pathPoints, options }) => {
    const worker = randomGeoWorker()
    return await worker.pathFrom({ origin, destination, pathPoints, options })
}

export const positionAndHeadingAlongPathWith = async ({ pathSegments, progress }) => {
    const worker = randomGeoWorker()
    return await worker.positionAndHeadingAlongPathWith({ pathSegments, progress })
}

export const pathFromStationSegmentPath = async ({ origin, destination, stationSegmentPath }) => {
    const worker = randomGeoWorker()
    return await worker.pathFromStationSegmentPath({ origin, destination, stationSegmentPath })
}