import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { VueQueryPlugin } from '@tanstack/vue-query'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import { VueHeadMixin, createHead } from '@unhead/vue'
import VueLogger from 'vuejs3-logger'
import VueCesium from 'vue-cesium'
import 'vue-cesium/dist/index.css'
import enUS from 'vue-cesium/es/locale/lang/en-us'
import '@/assets/css/global.css'

const isProd = process.env.VUE_APP_ORIGIN?.startsWith('https://')

const loggerOpts = {
  isEnabled: true,
  logLevel : isProd ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
}

loadFonts()

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: App },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: { 
      queries: {
        retry: false,
        gcTime: 10 * 60 * 1000 // 10 mins
      }
    },
  },
}

createApp(App)
  .use(router)
  .use(vuetify)
  .use(createHead())
  .mixin(VueHeadMixin)
  .use(mdiVue, {
    icons: mdijs
  })
  .use(VueLogger, loggerOpts)
  .use(VueCesium, {
    accessToken: process.env.VUE_APP_CESIUM_ACCESS_TOKEN,
    locale: enUS
  })
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .mount('#app')
