export const geoJSONDataFromPath = ({ path, route, originCode, destinationCode } = {}) => {
    const geoJSONFeatureCollectionLineString = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
                route,
                originCode,
                destinationCode
            },
            geometry: {
              coordinates: (path ?? []).map(({ lat, lng }) => ([lng,lat])),
              type: "LineString"
            }
          }
        ]
    }
    return JSON.stringify(geoJSONFeatureCollectionLineString)
}

export const pathFromGeoJSONLineString = ({ geoJSONData } = {}) => {
    const path = []
    if (geoJSONData?.type === "FeatureCollection" && geoJSONData?.features?.[0]?.geometry?.type === "LineString") {
        geoJSONData.features[0].geometry.coordinates.forEach(([lng, lat]) => {
            path.push({ lat, lng })
        });
    }
    return path
}