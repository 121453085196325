import { getStatistics } from "./dataUtils.js"
import { prettyDate, humanTimeSinceDate } from "./uiUtils.js"

// const fastestTrainDescriptionForStats = (statsData) => {
//     // TODO
// }

export const renderStatisticsTrivia = async () => {
    const statsTrivia = [
        `Welcome to Trak Watch!`,
        `Click a train on the map or select from the menu to start watching.`,
        `All train movement is simulated using location history from Amtrak.`,
        `New train location updates are received about every 3 minutes.`
    ]
    try {
        const statsData = await getStatistics()
        const sampleCount = statsData?.trainSampleSizeInfo?.aggregate?.count
        const firstSampleDatestamp = statsData?.trainSampleOldestItem?.nodes?.[0]?.created
        const topSpeedsByRoute = statsData?.topSpeedInfo?.nodes
        if (sampleCount !== undefined && firstSampleDatestamp !== undefined) {
            const firstSampleDate = new Date(firstSampleDatestamp)
            const firstSampleDateDescription = prettyDate(firstSampleDate)
            const firstSampleDateHumanizedSince = humanTimeSinceDate(firstSampleDate)
            statsTrivia.push(`${sampleCount.toLocaleString()} Amtrak location reports have been collected since ${firstSampleDateDescription} (${firstSampleDateHumanizedSince}).`)
            if (topSpeedsByRoute) {
                statsTrivia.push(`Fastest trains...`)
                topSpeedsByRoute.forEach(topSpeedNode => {
                    // screen any bogus readings
                    const routeTopSpeed = Number(topSpeedNode.speed)
                    if (routeTopSpeed < 300) {
                        statsTrivia.push(`${topSpeedNode.route} (${topSpeedNode.train}): ${routeTopSpeed.toPrecision(4)} MPH (${topSpeedNode.traintimestamp})`)
                    }
                })
            }
        }
    } catch (error) {
        console.log(`Error fetching statistics data: ${error}`)
    }
    return statsTrivia
}