import { computeGreatCircleDistance, headingThroughPoints, headingDiffDegrees, curvatureDegreeChord, milesToFeet, feetToMiles, meanHeadingThroughPoints } from './math.js'

const HINT_PATH_VERIFICATION_THRESHOLD_MULTIPLIER = 2.5

// Hint path processor
const createHintPath = ({
    hintPathPoints
}, 
// {
//     logger = null,
//     logDescription = null
// } = {}
) => {

    const path = {
        sortedPoints: [],
        pathSegments: [],
        overallPathLength: 0
    }

    // De-duplicate hint path points
    if (hintPathPoints && hintPathPoints.length > 0) {
        for (const hintPathPoint of hintPathPoints) {
            let addPoint = true
            const hintPathLength = path.sortedPoints.length
            if (hintPathLength > 0) {
                const lastHintPathPoint = path.sortedPoints[hintPathLength - 1]
                // Check for duplicate
                if (lastHintPathPoint.lat === hintPathPoint.lat && lastHintPathPoint.lng === hintPathPoint.lng) {
                    // Skip
                    addPoint = false
                }
            }

            if (addPoint) {
                path.sortedPoints.push(hintPathPoint)
            }
        }
    }

    // Determine path 'segments' including segment length
    for (let index = 0; index < path.sortedPoints.length - 1; index++) {
        const start = path.sortedPoints[index]
        const end = path.sortedPoints[index+1]
        const length = computeGreatCircleDistance({ lat1: start.lat, long1: start.lng, lat2: end.lat, long2: end.lng })
        path.pathSegments.push({
            start,
            end,
            length,
            offset: path.overallPathLength,
            heading: headingThroughPoints({ lat1: start.lat, long1: start.lng, lat2: end.lat, long2: end.lng })
        })
        path.overallPathLength += length
    }

    // Attribute progress range for each segment
    for (const seg of path.pathSegments) {
        // Start progress
        seg.progressStart = seg.offset / path.overallPathLength
        // End progress
        seg.progressEnd = (seg.offset + seg.length) / path.overallPathLength
    }

    return path
}

const findNearestNeighbor = ({ needleLocation, haystackLocations }) => {
    // Sort the haystack points by distance to the 'needle'
    const sortedHaystack = [...haystackLocations]
    sortedHaystack.sort((a, b) => {
        const distA = computeGreatCircleDistance({ lat1: a.lat, long1: a.lng, lat2: needleLocation.lat, long2: needleLocation.lng })
        const distB = computeGreatCircleDistance({ lat1: b.lat, long1: b.lng, lat2: needleLocation.lat, long2: needleLocation.lng })
        if (distA < distB) {
            return -1
        } else if (distA > distB) {
            return 1
        }
        return 0
    })
    // Pop the 'nearest' haystack point
    return sortedHaystack.shift()
}

// Path finder logic handler
const createPath = ({
    origin,
    destination,
    pathPoints,
    options: {
        excludeOrigin,
        excludeDestination,
        curveLimitDegrees,
        curveLimitRangeFeet,
        curveLimitSkipMax,
        headingSmoothingDepth,
        useHintPath
    } = {} 
}, {
    logger = null,
    logDescription = null
} = {}) => {
    const unsorted = [...pathPoints]
    let pathTail = origin

    const path = {
        sortedPoints: excludeOrigin ? [] : [origin],
        sortedPointsHeadings: excludeOrigin ? [] : [null],
        sortedPointsDistances: excludeOrigin ? [] : [null],
        pathSegments: [],
        overallPathLength: 0
    }

    let headingFilterStreak = 0 // track and limit 'heading' filtering while building path

    const directFlightDistance = computeGreatCircleDistance({ lat1: origin.lat, long1: origin.lng, lat2: destination.lat, long2: destination.lng })

    const fiveFootRadiusInMiles = feetToMiles(5)

    // Add hint path points to the unsorted pool and use to further sanity-check the path finding
    if (useHintPath?.sortedPoints && useHintPath.sortedPoints.length > 0) {
        unsorted.push(...(
            useHintPath.sortedPoints.map((aSortedPoint, idx) => ({ lng: aSortedPoint.lng, lat: aSortedPoint.lat, hintIndex: idx }))
        ))
    }

    let lastHintCheckpoint = -1 // The last hint path location encountered in the path rendering

    // Sort the path points
    // Append to the path (tail) with the 'closest' remaining unsorted point
    while (unsorted.length) {
        // Sort the unsorted points by distance to the path's 'tail
        unsorted.sort((a, b) => {
            const distA = computeGreatCircleDistance({ lat1: a.lat, long1: a.lng, lat2: pathTail.lat, long2: pathTail.lng })
            const distB = computeGreatCircleDistance({ lat1: b.lat, long1: b.lng, lat2: pathTail.lat, long2: pathTail.lng })
            if (distA < distB) {
                return -1
            } else if (distA > distB) {
                return 1
            }
            return 0
        })
        // Pop the 'closest' point
        const nextPathPoint = unsorted.shift()

        // Sanity Check that the next closest point isn't a duplicate (very close)
        const distNext = computeGreatCircleDistance({ lat1: pathTail.lat, long1: pathTail.lng, lat2: nextPathPoint.lat, long2: nextPathPoint.lng })

        // Sanity check results
        let debugLabel = null
        let nextHeading = null

        // Check for a hint path checkpoint
        if (nextPathPoint.hintIndex != undefined) {
            if (nextPathPoint.hintIndex > lastHintCheckpoint) {
                logger?.debug(`Reached hint path checkpoint: ${lastHintCheckpoint} --> ${nextPathPoint.hintIndex}`)
                lastHintCheckpoint = nextPathPoint.hintIndex
            } else {
                // Back-tracking? Skip this point.
                continue // while
            }
        } else {
            // Perform normal sanity checks
            if (distNext <= fiveFootRadiusInMiles) {
                logger?.debug(`Tossing out point from path render due to close proximity: ${milesToFeet(distNext)} feet`)
                continue // while
            }
            
            // Sanity Check that the next closest point isn't much farther away than the final destination
            const distEnd = computeGreatCircleDistance({ lat1: pathTail.lat, long1: pathTail.lng, lat2: destination.lat, long2: destination.lng })
            if (distNext >= distEnd) {
                logger?.debug((logDescription ? `(${logDescription}) s` : 'S') + `skipping point in path render due to range vs destination. (dist to point: ${distNext}, dist to end: ${distEnd})`)
                continue // while loop
            }
    
            // Sanity check that the next closest point doesn't imply an unrealistic track curvature ( based on current smoothed heading)
            // (i.e. trains are typically limited to '10 degree' curves or less)
            const distNextFeet = milesToFeet(distNext)
            let curveDegree = null
            if (path.sortedPoints.length >= (headingSmoothingDepth + 1) && unsorted.length >= 2) {
                // Compute the current path 'heading' average based on the sample path segment
                const headingPoints = path.sortedPoints.slice(-1 * (headingSmoothingDepth + 1))
                const currentHeading = meanHeadingThroughPoints({ points: headingPoints })
    
                const insideCurvatureRange = distNextFeet <= curveLimitRangeFeet
    
                // Compute the next segment 'heading'
                const lastTwoPathPoints = path.sortedPoints.slice(-2)
                nextHeading = headingThroughPoints({
                    lat1: lastTwoPathPoints[1].lat,
                    long1: lastTwoPathPoints[1].lng,
                    lat2: nextPathPoint.lat,
                    long2: nextPathPoint.lng
                })
                const headingDiff = headingDiffDegrees({ heading1: currentHeading, heading2: nextHeading })
                
                if (insideCurvatureRange) {
                    // Exclude the nearest point based on curvature limit:
                    // Check the implied degree of curvature (using 'chord length')
                    curveDegree = curvatureDegreeChord({ chordDistanceMiles: distNext, headingDiff })
                }
                else {
                    // Exclude nearest point based on heading change (disregard distance)
                    curveDegree = headingDiff
                }
                
                if (curveDegree > curveLimitDegrees) {
                    const withinSkipLimit = headingFilterStreak <= curveLimitSkipMax
    
                    if (insideCurvatureRange && withinSkipLimit) {
                        logger?.debug((logDescription ? `(${logDescription}) s` : 'S') + `skipping point in path render due to large curve degree. (heading: ${currentHeading}, proposed curveDegree: ${curveDegree})`)
                        headingFilterStreak += 1
                        continue // while loop
                    } else if (insideCurvatureRange) {
                        debugLabel = `Curve warn: ${curveDegree.toPrecision(3)}`
                    }
                } else if (headingFilterStreak > (curveLimitSkipMax / 2.0)) {
                    debugLabel = `Curve skipped: ${headingFilterStreak}`
                }
            }
    
            // Don't route to point farther away than 10% of distance between origin and destination
            if (distNext >= (directFlightDistance / 10)) {
                if (excludeDestination && unsorted.length === 0) {
                    // We need to keep the last point in the path here
                } else {
                    // We can drop this point
                    logger?.debug((logDescription ? `(${logDescription}) s` : 'S') + `skipping point in path render due to large gap: ${distNext} miles.`)
                    continue // while loop
                }
            }
        }
        
        // Point passes sanity checks; add to path
        path.sortedPoints.push({ ...nextPathPoint, debugLabel, hintIndex: lastHintCheckpoint })
        path.sortedPointsDistances.push(distNext)
        path.overallPathLength += distNext
        path.sortedPointsHeadings.push(nextHeading)
        pathTail = nextPathPoint

        // Update filter stats
        headingFilterStreak = 0
    }
    if (!excludeDestination) {
        path.sortedPoints.push(destination)
        path.sortedPointsHeadings.push(null)
        path.sortedPointsDistances.push(null)
    }

    return path
}



/**
 * Create a path from origin to destination using the waypoints supplied
 * Path includes metadata about 'segments'
 * @param {Object} origin - { lat, lng }, start of the path
 * @param {Object} destination - { lat, lng }, end of the path
 * @param {Array<Object>} pathPoints - unsorted waypoints along the path, { lat, lng }
 * @param {Object} options - path rendering options
    - excludeOrigin - exclude the origin point from the resulting path
    - excludeDestination - exclude the destination point from the resulting path
    - curveLimitDegrees - upper limit for track curvature when rendering path (degrees of curvature)
    - curveLimitRangeFeet - range for absolute track curvature enforcement (feet)
    - curveLimitSkipMax - limit for consecutive path points skipped due to track curvature limit
    - headingSmoothingDepth - number of train positions used for average heading determination
    - twoPass - compute path from origin -> destination, destination -> origin and select the shortest path
    - useHintPath - use the supplied ordered list of points as a hint for rendering the path (sections between hint points are rendered recursively)
 * @return {Object} { sortedPoints: [{ lat, lng, curve }], pathSegments: [{ start, end, length, progressStart, progressEnd, offset, heading }]}
 */
export const pathFrom = ({
    origin,
    destination,
    pathPoints,
    options: {
        excludeOrigin = false,
        excludeDestination = false,
        curveLimitDegrees = 15,
        curveLimitRangeFeet = 500,
        curveLimitSkipMax = 10,
        headingSmoothingDepth = 5,
        hintPathFailsafeThresholdRatio = HINT_PATH_VERIFICATION_THRESHOLD_MULTIPLIER,
        // twoPass = true,
        useHintPath = null,
    } = {} 
}, {
    logger = null,
    logDescription = null
} = {}) => {

    let hintPath = null
    if (useHintPath && useHintPath.length > 0) {
        // TODO
        // Use the hint paths points to prevent back-tracking and keep path rendering flowing toward the destination
        // For example, if the next closest path candidate point is farther from the next hint point than the previous hint point, discard the candidate

        // Use hint path to get general path length for sanity checking forward/backward paths
        hintPath = createHintPath({ hintPathPoints: useHintPath })
    }

    const forwardPath = createPath({
        origin,
        destination,
        pathPoints,
        options: {
            excludeOrigin,
            excludeDestination,
            curveLimitDegrees,
            curveLimitRangeFeet,
            curveLimitSkipMax,
            headingSmoothingDepth,
            useHintPath: hintPath
        }
    }, { logger, logDescription })

    if (hintPath && hintPath.sortedPoints?.length > 0) {
        const hintSegments = {}
        const mergedPathPoints = []
        for (const pathPoint of forwardPath.sortedPoints) {
            const thisPathPointHintIndex = pathPoint.hintIndex ?? -1
            const hintSegmentPoints = hintSegments[`${thisPathPointHintIndex}`] ?? []
            hintSegmentPoints.push(pathPoint)
            hintSegments[`${thisPathPointHintIndex}`] = hintSegmentPoints
        }
    
        // Compare each 'hint' segment length with rendered path section
        // Initial segment (before hint location '0')
        const initialPathSegment = hintSegments['-1']
        if (initialPathSegment && initialPathSegment.length > 0) {
            // Get distance from path origin to first hint path location
            const firstPathPoint = initialPathSegment[0]
            const firstHintPoint = hintPath.sortedPoints[0]
            const initialPathSegmentHintDistance = computeGreatCircleDistance({ lat1: firstPathPoint.lat, long1: firstPathPoint.lng, lat2: firstHintPoint.lat, long2: firstHintPoint.lng })

            // Compute rendered section length
            let thisSectionLength = 0
            for (let index = 0; index < initialPathSegment.length - 1; index++) {
                const start = initialPathSegment[index]
                const end = initialPathSegment[index+1]
                const length = computeGreatCircleDistance({ lat1: start.lat, long1: start.lng, lat2: end.lat, long2: end.lng })
                thisSectionLength += length
            }
            // Swap out rendered segment with hint path segment if too long
            if (thisSectionLength > (hintPathFailsafeThresholdRatio * initialPathSegmentHintDistance)) {
                // Use the hint path points
                mergedPathPoints.push(firstPathPoint)
                mergedPathPoints.push(firstHintPoint)
            } else {
                mergedPathPoints.push(...initialPathSegment)
            }
        }
        let hintPathIndex = 0
        for (const hintPathSegment of hintPath.pathSegments) {
            const thisSectionPoints = hintSegments[`${hintPathIndex}`]
            if (thisSectionPoints && thisSectionPoints.length > 0) {
                // Compute rendered section length
                let thisSectionLength = 0
                for (let index = 0; index < thisSectionPoints.length - 1; index++) {
                    const start = thisSectionPoints[index]
                    const end = thisSectionPoints[index+1]
                    const length = computeGreatCircleDistance({ lat1: start.lat, long1: start.lng, lat2: end.lat, long2: end.lng })
                    thisSectionLength += length
                }
                // Swap out very long segments with hint path segments
                if (thisSectionLength > (hintPathFailsafeThresholdRatio * hintPathSegment.length)) {
                    // Use the hint path points
                    mergedPathPoints.push(hintPathSegment.start)
                    mergedPathPoints.push(hintPathSegment.end)
                } else {
                    mergedPathPoints.push(...thisSectionPoints)
                }
            }
            hintPathIndex += 1
        }
        // Final segment (after last hint path location)
        const finalPathSegment = hintSegments[`${hintPathIndex}`]
        const finalPathSegmentLength = finalPathSegment?.length ?? 0
        const hintPathLength = hintPath.sortedPoints.length
        if (finalPathSegment && finalPathSegmentLength > 0) {
            // Get distance from last hint path location to destination location
            const lastHintPoint = hintPath.sortedPoints[hintPathLength - 1]
            const finalPathLocation = findNearestNeighbor({ needleLocation: destination, haystackLocations: finalPathSegment })
            const finalPathSegmentHintDistance = computeGreatCircleDistance({ lat1: lastHintPoint.lat, long1: lastHintPoint.lng, lat2: finalPathLocation.lat, long2: finalPathLocation.lng })

            // Compute rendered section length
            let thisSectionLength = 0
            for (let index = 0; index < finalPathSegmentLength - 1; index++) {
                const start = finalPathSegment[index]
                const end = finalPathSegment[index+1]
                const length = computeGreatCircleDistance({ lat1: start.lat, long1: start.lng, lat2: end.lat, long2: end.lng })
                thisSectionLength += length
            }
            // Swap out rendered segment with hint path segment if too long
            if (thisSectionLength > (hintPathFailsafeThresholdRatio * finalPathSegmentHintDistance)) {
                // Use the last rendered path point only
                mergedPathPoints.push(finalPathLocation)
            } else {
                // Add all final segment points from rendered path
                mergedPathPoints.push(...finalPathSegment)
            }
        }

        // Update path with 'merged' points
        forwardPath.sortedPoints = mergedPathPoints
        forwardPath.sortedPointsDistances = [] // Clear calculated distances
        forwardPath.sortedPointsHeadings = [] // Clear calculated headings
    }

    // Determine path 'segments' including segment length
    // Note: Reuse the computed data from the path if available
    let overallPathLength = 0
    for (let index = 0; index < forwardPath.sortedPoints.length - 1; index++) {
        const start = forwardPath.sortedPoints[index]
        const end = forwardPath.sortedPoints[index+1]
        const length = forwardPath.sortedPointsDistances?.[index+1] ?? computeGreatCircleDistance({ lat1: start.lat, long1: start.lng, lat2: end.lat, long2: end.lng })
        const thisPathSegment = {
            start,
            end,
            length,
            offset: overallPathLength,
            heading: forwardPath.sortedPointsHeadings?.[index+1] ?? headingThroughPoints({ lat1: start.lat, long1: start.lng, lat2: end.lat, long2: end.lng })
        }
        forwardPath.pathSegments.push(thisPathSegment)
        overallPathLength += length
    }

    // Attribute progress range for each segment
    for (const seg of forwardPath.pathSegments) {
        // Start progress
        seg.progressStart = seg.offset / overallPathLength
        // End progress
        seg.progressEnd = (seg.offset + seg.length) / overallPathLength
    }

    return forwardPath
}

/**
 * Compute a traveler's position and heading given the traveler's path and overall progress
 * @param {Array<Object>} pathSegments - An array of 'segments' (edges) with metadata (from pathFrom())
 * @param {Number} progress - the overall progress through the path on the range [0, 1] where 0 = 0% and 1 = 100%
 */
export const positionAndHeadingAlongPathWith = ({ pathSegments, progress }) => {
    let currentPosition
    let currentHeading
    // Find the current path 'segment' based on progress
    for (const seg of pathSegments) {
        // Active segment?
        if (progress >= seg.progressStart && progress < seg.progressEnd) {
            // Compute progress within this segement
            const segmentProgress = (progress - seg.progressStart) / (seg.progressEnd - seg.progressStart)
            // Interpolate (linearly) the current position along segment based on segment progress
            currentPosition = {
                lat: seg.start.lat + (seg.end.lat - seg.start.lat) * segmentProgress,
                lng: seg.start.lng + (seg.end.lng - seg.start.lng) * segmentProgress
            }
            // Use the segment's heading
            currentHeading = seg.heading
            break // for
        }
    }
    return { currentPosition, currentHeading }
}

/**
 * Create a path from origin to destination using existing, rendered station segment path
 * Path includes metadata about 'segments'
 * @param {Object} origin - { lat, lng }, start of the path
 * @param {Object} destination - { lat, lng }, end of the path
 * @param {Object} stationSegmentPath - [{ lat, lng }]
 * @return {Object} { sortedPoints: [{ lat, lng }], pathSegments: [{ start, end, length, progressStart, progressEnd, offset, heading }]}
 */
export const pathFromStationSegmentPath = ({ origin, destination, stationSegmentPath = [] }) => {

    const path = {
        sortedPoints: [],
        pathSegments: [] // segment objects
    }

    // Find entry point in station segment path nearest to the origin
    // Find exit point in station segment path nearest to the destination
    let entryPointIndex
    let distToOrigin
    let exitPointIndex
    let distToDestination
    stationSegmentPath.forEach((stationSegmentPoint, idx) => {
        const distOrigin = computeGreatCircleDistance({ lat1: origin.lat, long1: origin.lng, lat2: stationSegmentPoint.lat, long2: stationSegmentPoint.lng })
        if (!distToOrigin || distOrigin < distToOrigin) {
            distToOrigin = distOrigin
            entryPointIndex = idx
        }
        
        const distDestination = computeGreatCircleDistance({ lat1: destination.lat, long1: destination.lng, lat2: stationSegmentPoint.lat, long2: stationSegmentPoint.lng })
        if (!distToDestination || distDestination < distToDestination) {
            distToDestination = distDestination
            exitPointIndex = idx
        }
    })

    // Note: Trimming the extracted path by one point on each end to avoid "backtracking"

    // Construct new path by reusing station segment path elements
    if (entryPointIndex && exitPointIndex) {
        path.sortedPoints = [origin, ...(stationSegmentPath.slice(entryPointIndex + 1, exitPointIndex)), destination ]
    } else {
        path.sortedPoints = [origin, destination]
    }
    

    // Determine path 'segments' including segment length
    let overallPathLength = 0
    for (let index = 0; index < path.sortedPoints.length - 1; index++) {
        const start = path.sortedPoints[index]
        const end = path.sortedPoints[index+1]
        const length = computeGreatCircleDistance({ lat1: start.lat, long1: start.lng, lat2: end.lat, long2: end.lng })
        path.pathSegments.push({
            start,
            end,
            length,
            offset: overallPathLength,
            heading: headingThroughPoints({ lat1: start.lat, long1: start.lng, lat2: end.lat, long2: end.lng })
        })
        overallPathLength += length
    }

    // Attribute progress range for each segment
    for (const seg of path.pathSegments) {
        // Start progress
        seg.progressStart = seg.offset / overallPathLength
        // End progress
        seg.progressEnd = (seg.offset + seg.length) / overallPathLength
    }
    return path
}