import moment from 'moment'

export const prettyStringForEnum = (rawString) => {
    let pretty = rawString
    switch (rawString) {
        // Pretty Strings
        default:
            break
    }
    return pretty
}

export const isDatePast = (rawDate) => {
    return moment(rawDate).isBefore() // before now by default
  }

export const prettyDate = (rawDate) => {
    return moment(rawDate).format('MMMM Do YYYY')
}

export const prettyDateWithFormat = (rawDate, dateFormat = null) => {
    return moment(rawDate).format(dateFormat ? dateFormat: 'MMMM Do YYYY')
}

export const humanTimeSinceDate = (date) => {
    const now = new Date()
    const durationDescription = `${moment.duration(moment(now).diff(moment(date))).humanize()}`
    return moment(date).isBefore(now) ? `${durationDescription} ago` : `in ${durationDescription}`
}

export const isStringEmpty = (stringValue) => {
    let isEmpty = true
    if (stringValue && stringValue.trim()) {
        isEmpty = false
    }
    return isEmpty
}