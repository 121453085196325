import Cache from "timed-cache"

// Setup a TTL cache for rendered station segment paths
const stationSegmentCache = new Cache({
    defaultTtl: 2 * 60 * 60 * 1000 // 4 hours
})

// Setup a TTL cache for route movement samples (completed train movements)
const routeSampleMovementCache = new Cache({
    defaultTtl: 4 * 60 * 60 * 1000 // 4 hours
})

export const segmentKeyFor = ({ route, startStationCode, endStationCode }) => {
    return `${route}-${startStationCode.toLowerCase()}-${endStationCode.toLowerCase()}`
}

export const routeSampleKeyFor = ({ route, trainNum }) => {
    return `${route.toLowerCase()}-${trainNum.toLowerCase()}`
}

export const checkForSegmentInCache = ({ route, startStationCode, endStationCode }) => {
    if (route && startStationCode && endStationCode) {
        const segKey = segmentKeyFor({ route, startStationCode, endStationCode })
        const val = stationSegmentCache.get(segKey)
        if (val) {
            return true
        }
    }
    return false
}

export const checkForRouteSampleInCache = ({ route, trainNum }) => {
    if (route && trainNum) {
        const segKey = routeSampleKeyFor({ route, trainNum })
        const val = routeSampleMovementCache.get(segKey)
        if (val) {
            return true
        }
    }
    return false
}

export const getSegmentFromCache = ({ route, startStationCode, endStationCode }) => {
    const segKey = segmentKeyFor({ route, startStationCode, endStationCode })
    return stationSegmentCache.get(segKey)
}

export const getRouteSampleFromCache = ({ route, trainNum }) => {
    const segKey = routeSampleKeyFor({ route, trainNum })
    return routeSampleMovementCache.get(segKey)
}

export const insertSegmentInCache = ({ route, startStationCode, endStationCode, data }) => {
    const segKey = segmentKeyFor({ route, startStationCode, endStationCode })
    stationSegmentCache.put(segKey, data)
}

export const insertRouteSampleInCache = ({ route, trainNum, data }) => {
    const segKey = routeSampleKeyFor({ route, trainNum })
    routeSampleMovementCache.put(segKey, data)
}

export const clearRouteSampleCache = () => {
    routeSampleMovementCache.clear()
}

export const clearSegmentCache = () => {
    stationSegmentCache.clear()
}
