import { prettyDateWithFormat } from './uiUtils.js'
import { getStationSegmentPoints, getRouteSampleMovement } from './dataUtils.js'
import { pathFrom } from './workerUtils.js'

export const getTrainHeadingForData = (trainDatum) => {
    if (trainDatum.headingDegrees !== undefined) {
      return trainDatum.headingDegrees
    }
    const trainHeadingString = trainDatum.heading ?? 'E'
    let trainHeading = 90
    switch (trainHeadingString) {
      case 'N':
        trainHeading = 0
        break
      case 'S':
        trainHeading = 180
        break
      case 'W':
        trainHeading = 270
        break
      case 'NE':
        trainHeading = 45
        break
      case 'NW':
        trainHeading = 315
        break
      case 'SW':
        trainHeading = 225
        break
      case 'SE':
        trainHeading = 135
        break
      default:
        break
    }
    return trainHeading
  }

  // Translate heading in degrees to cardinal direction string (e.g. "N" or "NE")
  // Note: adjustForTilt (= true) limits cardinal directions to "N", "E", "W", "S" for perspectives tilted away from perpendicular
  export const getHeadingStringForHeading = (heading, adjustForTilt = false) => {
    let trainHeadingString = 'E'
    if (adjustForTilt) {
      switch (true) {
        case (heading < 45.0 || heading >= 315.0):
          trainHeadingString = 'N'
          break
        case (heading >= 45.0 && heading < 135.0):
          trainHeadingString = 'E'
          break
        case (heading >= 135 && heading < 225.0):
          trainHeadingString = 'S'
          break
        case (heading >= 225.0 && heading < 315.0):
          trainHeadingString = 'W'
          break
        default:
          break
      }
    } else {
      switch (true) {
        case (heading < 22.5 || heading >= 337.5):
          trainHeadingString = 'N'
          break
        case (heading >= 22.5 && heading < 67.5):
          trainHeadingString = 'NE'
          break
        case (heading >= 67.5 && heading < 112.5):
          trainHeadingString = 'E'
          break
        case (heading >= 112.5 && heading < 157.5):
          trainHeadingString = 'SE'
          break
        case (heading >= 157.5 && heading < 202.5):
          trainHeadingString = 'S'
          break
        case (heading >= 202.5 && heading < 247.5):
          trainHeadingString = 'SW'
          break
        case (heading >= 247.5 && heading < 292.5):
          trainHeadingString = 'W'
          break
        case (heading >= 292.5 && heading < 337.5):
          trainHeadingString = 'NW'
          break
        default:
          break
      }
    }
    return trainHeadingString
  }

  // Train Data Helpers
  export const trainItemLabelForData = (trainDatum) => {
    return `${trainDatum.routeName} #${trainDatum.trainNum} (${prettyDateWithFormat(new Date(trainDatum.origSchDep), 'MM-DD-YY')}, ${trainDatum.origCode} -> ${trainDatum.destCode})`
  }
  export const trainItemShortLabelForData = (trainDatum) => {
    return `#${trainDatum.trainNum} ${trainDatum.origCode} -> ${trainDatum.destCode} (${prettyDateWithFormat(new Date(trainDatum.origSchDep), 'MM-DD-YY')})`
  }
  export const trainCoordsForData = (trainDatum) => trainDatum.coordinates
  export const trainLastStationCodeForData = (trainDatum) => trainDatum?.lastStation?.code ?? null
  export const trainLastStationCoordsForData = (trainDatum) => trainDatum?.lastStation?.info?.geometry?.coordinates ?? [null, null]
  export const trainNextStationCodeForData = (trainDatum) => trainDatum?.nextStation?.code ?? null
  export const trainNextStationCoordsForData = (trainDatum) => trainDatum?.nextStation?.info?.geometry?.coordinates ?? [null, null]
  export const trainTSForData = (trainDatum) => trainDatum.lastValTrainTS ?? null
  
  export const rawDataStringForTrainData = (trainDatum) => {
    return JSON.stringify({ ...trainDatum, stations: undefined }, null, 2) // strip stations for readability
  }

  export const trainSourceAndDestinationStationsListForSegment = ({ trainDatum, lastStationCode, nextStationCode }) => {
    const destCodes = []
    const previousCodes = []
    if (trainDatum && lastStationCode && nextStationCode) {
        // Get the station codes list for this train from the data
        const allStationCodes = trainDatum.stations.map(({ code }) => code)
        let foundLastStationCode = false
        for (let stationIdx = 0; stationIdx < allStationCodes.length; stationIdx++) {
            const aStationCode = allStationCodes[stationIdx]
            const aPreviousCode = stationIdx === 0 ? null : allStationCodes[stationIdx - 1]
            if (aStationCode === lastStationCode) {
                foundLastStationCode = true
            } else if (aStationCode === nextStationCode) {
                destCodes.push(aStationCode) // Add this final destination station code to the list
                previousCodes.push(aPreviousCode) // Add the previous station to the previous stations list
                break // for
            } else if (foundLastStationCode) {
                destCodes.push(aStationCode) // Add this intervening destination station to the list
                previousCodes.push(aPreviousCode) // Add the previous station to the previous stations list
            }
        }
    }
    return { destCodes, previousCodes }
  }

  // Train path utils

  export const fetchAndRenderTrainStationSegment = async ({
    route,
    trainNum,
    originLat,
    originLng,
    destLat,
    destLng,
    maxResults,
    destStationCodes,
    previousStationCodes,
    curveLimitDegrees,
    curveLimitRangeFeet,
    curveLimitSkipMax,
    hintPathFailsafeThresholdRatio,
    useProd 
  }) => {
    const { results: pathPoints } = await getStationSegmentPoints({
      route,
      maxResults,
      destStationCodes,
      previousStationCodes,
      useProd
    })

    const hintPath = await getRouteSampleMovement({
      route,
      destStationCodes,
      trainNum,
      useProd
    })

    // Render the station segment path
    const {
      sortedPoints
    } = await pathFrom({
        origin: { lat: originLat, lng: originLng }, 
        destination: { lat: destLat, lng: destLng }, 
        pathPoints: pathPoints,
        options: {
          excludeOrigin: true,
          excludeDestination: true,
          curveLimitDegrees,
          curveLimitRangeFeet,
          curveLimitSkipMax,
          hintPathFailsafeThresholdRatio,
          useHintPath: hintPath
        }
      })
    
    // Station Segment Path
    return { sortedPoints, pathPoints }
  }